<template>
  <div>
    <el-card class="card" :bodyStyle="{ height: '100%' }">
      <div class="cardBody">
        <div
          style="
            display: flex;
            flex-direction: column;
            height: calc(100vh - 180px);
          "
        >
          <div style="display: flex; flex-wrap: wrap">
            <div class="condition-item">
              <el-button type="success" v-if="addButton" @click="addTemplates()"
                >新增模板</el-button
              >
            </div>
          </div>

          <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
            <el-table
              :data="TemplatesList"
              v-loading="tableLoading"
              stripe
              border
              height="100%"
              style="width: 100%"
            >
            <template #empty>
                <p>
                  {{ tableLoading == true ? "数据加载中" : "暂无数据" }}
                </p>
              </template>

              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                fixed
                prop="templateName"
                label="模板名称"
                width="200"
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="orderId"
                label="工单号"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="templateCode"
                label="模板CODE"
                width="200"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="templateType"
                label="模板类型"
                width="150 "
                align="center"
              >
                <template slot-scope="scope">
                  <span v-show="scope.row.templateType == 0">短信通知</span>
                  <span v-show="scope.row.templateType == 1">推广短信</span>
                  <span v-show="scope.row.templateType == 2">验证码短信</span>
                  <span v-show="scope.row.templateType == 6"
                    >国际/港澳台短信</span
                  >
                  <span v-show="scope.row.templateType == 7">数字短信</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createDate"
                label="创建时间"
                width="200"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="auditStatus"
                label="审核状态"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag v-show="scope.row.auditStatus == 'AUDIT_STATE_INIT'"
                    >审核中</el-tag
                  >
                  <el-tag
                    v-show="scope.row.auditStatus == 'AUDIT_STATE_PASS'"
                    type="success"
                    >审核通过</el-tag
                  >
                  <el-tag
                    type="danger"
                    v-show="scope.row.auditStatus == 'AUDIT_STATE_NOT_PASS'"
                    >审核未通过</el-tag
                  >
                  <el-tag
                    type="info"
                    v-show="scope.row.auditStatus == 'AUDIT_STATE_CANCEL'"
                    >取消审核</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column prop="templateContent" label="模板内容">
              </el-table-column>
              <el-table-column
                prop="edit"
                label="操作"
                width="150"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="uploadButton"
                    type="text"
                    v-show="scope.row.auditStatus == 'AUDIT_STATE_NOT_PASS'"
                    @click="addTemplates(scope.row.templateCode)"
                    >修改
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div style="display: flex; justify-content: right">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
    <templatedit ref="templateEditForm" @funSearch="funSearch" />
  </div>
</template>

<script>
import { queryTemplates } from "@/api/sms";
import { queryPageButton } from "@/api/permission";
import templatedit from "./extend/templatedit.vue";
export default {
  name: "SmsTemplate",
  components: {
    templatedit, //新增
  },
  data() {
    return {
      TemplatesList: [],
      tableLoading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      //权限按钮
      uploadButton: false,
      addButton: false,
    };
  },
  created() {
    this.query();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 237;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "uploadButton") {
            this.uploadButton = true;
          }
          if (button.code === "addButton") {
            this.addButton = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;
      queryTemplates(this.currentPage, this.pageSize).then((resp) => {
        const content = resp.data.content;
        this.TemplatesList = content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },

    addTemplates(templateCode) {
      let data = templateCode || "";
      if (this.$refs.templateEditForm != undefined) {
        this.$refs.templateEditForm.initData(data);
      }
    },
    funSearch() {
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
