<template>
  <div>
    <el-dialog :title="titles" :visible.sync="visible" center width="500px">
      <el-form
        ref="templateFrom"
        :model="templateData"
        label-width="120px"
        :rules="rulesForm"
      >
        <el-form-item label="模板名称" prop="templateName">
          <el-input
            v-model="templateData.templateName"
            style="width: 300px"
          ></el-input>
        </el-form-item>

        <el-form-item label="模板内容" prop="templateContent">
          <el-input
            v-model="templateData.templateContent"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板类型" prop="templateType">
          <el-select
            style="width: 300px"
            v-model="templateData.templateType"
            placeholder="请选择模板类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板备注" prop="remark">
          <el-input
            type="textarea"
            v-model="templateData.remark"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="visible = false">取 消</el-button>
        <el-button @click="SubmitEvent" type="primary" :loading="ConfirmLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { editTemplate, getTemplate, addTemplate } from "@/api/sms";
export default {
  name: "templatedit",

  data() {
    const validatetName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("模板名称不能为空"));
      } else {
        callback();
      }
    };
    const validateRemark = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请填写模板备注"));
      } else {
        callback();
      }
    };
    const validateContent = (rule, value, callback) => {
      if (!value) {
        callback(new Error("模板内容不能为空"));
      } else {
        callback();
      }
    };
    const validateType = (rule, value, callback) => {
      if (value !== 0 && value !== 1 && value !== 2 && value !== 3) {
        callback(new Error("请选择模板类型"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      ConfirmLoading: false,
      titles: "新增模板",
      templateData: {},
      typeList: [
        { label: "验证码", value: 0 },
        { label: "短信通知", value: 1 },
        { label: "推广短信", value: 2 },
        { label: "国际/港澳台消息", value: 3 },
      ],
      rulesForm: {
        templateName: [
          {
            required: true,
            trigger: "blur",
            validator: validatetName,
          },
        ],
        templateType: [
          {
            required: true,
            trigger: "blur",
            validator: validateType,
          },
        ],
        templateContent: [
          {
            required: true,
            trigger: "blur",
            validator: validateContent,
          },
        ],
        remark: [
          {
            required: true,
            trigger: "blur",
            validator: validateRemark,
          },
        ],
      },
    };
  },
  methods: {
    initData(data) {
      this.templateData = {};
      this.titles = "新增模板";
      if (data) {
        this.titles = "修改模板";
        getTemplate(data).then((res) => {
          if (res.code == 0) {
            this.templateData = res.data;
          }
        });
      }
      this.visible = true;
    },
    //保存模板
    SubmitEvent() {
      this.$refs.templateFrom.validate((valid) => {
        this.ConfirmLoading = true;
        if (valid) {
          let postdata = this.templateData;
          let url = "";
          if (this.titles == "修改模板") {
            url = editTemplate;
          } else {
            url = addTemplate;
          }
          url(postdata).then((res) => {
            if (res.code == 0) {
              this.$notify.success({
                title: "成功",
                message: res.message,
              });
            } else {
              this.$notify({
                title: "失败",
                message: res.message,
                type: "warning",
              });
            }
            this.visible = false;
            this.$emit("funSearch");
          });
        } else {
          this.ConfirmLoading = false;
          return false;
        }
      });
    },
  },
};
</script>
